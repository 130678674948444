export const APP_SETTINGS = {
    bookStatus: {
        NOT_STARTED: "not started",
        pendingPayment: "pending payment",
        inProcess: "in process",
        approved: "approved",
        pendingUserApproval: "pending user approval",
        returned: "returned",
        GENERATING_POST_PAY_PAGES: "GENERATING_POST_PAY_PAGES",
        GENERATING_PRINT_FILES: "GENERATING_PRINT_FILES",
        PRINT_FILES_GENERATED: "PRINT_FILES_GENERATED",
        GENERATING_PRINT_FILES_FAILED: "GENERATING_PRINT_FILES_FAILED",
        CREATION_FAILED: "creation failed",
        COMPLETION_FAILED: "completion failed",
        FAILED: "failed"
    },
    pages: {
        progress: "/progress",
        preview: "/preview",
        otp: "/otp",
        edit: "/edit",
        approved: "/approved",
        returned: "/returned"
    }
};
