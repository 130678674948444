import React from "react";
import styled from "styled-components";

export const PayForMoreStyledPayItem = styled.div`
    position: absolute;
    width: 100%;
`;

export const PayForMoreTextStyledPay = styled.div`
    background: rgba(000, 000, 000, 0.4);
    position: absolute;
    width: 100%;
    bottom: 8px;
    height: 90px;
    color: #fff;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    text-align: center;
    font-size: 20px;
    align-content: center;
    display: grid;

    .text-p-zdwe {
        font-size: 25px;
        //margin-top: 44px;
        color: #fff;
    }
`;

const ShowMediaPay = ({ tabIndex, label, text }) => {
    return (
        <PayForMoreStyledPayItem>
            <PayForMoreTextStyledPay aria-label={label} tabIndex={tabIndex}>
                <div className="text-p-zdwe">{text}</div>
            </PayForMoreTextStyledPay>
        </PayForMoreStyledPayItem>
    );
};

export default ShowMediaPay;
