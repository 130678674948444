import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const Popover = ({ children, content, position = "bottom", style, lang, forceVisibilityHandle, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);
    const triggerRef = useRef(null);
    const popoverRef = useRef(null);

    useEffect(() => {
        if (forceVisibilityHandle) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [forceVisibilityHandle]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                triggerRef.current &&
                !triggerRef.current.contains(event.target)
            ) {
                setIsVisible(false);
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const togglePopover = (e) => {
        e.stopPropagation();
        setIsVisible(!isVisible);
        if (!isVisible) {
            onClose();
        }
    };

    const getPopoverStyle = () => {
        const style = {
            position: "absolute",
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "4px 4px 12px -4px #0004",
            borderRadius: "4px"
        };

        switch (position) {
            case "top":
                style.bottom = "120%";
                lang == "he" ? (style.right = "0%") : (style.left = "0%");
                style.transform = "translateX(-50%)";
                break;
            case "bottom":
                style.top = "100%";
                style.left = "50%";
                style.transform = "translateX(-50%)";
                break;
            case "left":
                style.top = "50%";
                style.right = "100%";
                style.transform = "translateY(-50%)";
                break;
            case "right":
                style.top = "50%";
                style.left = "100%";
                style.transform = "translateY(-50%)";
                break;
        }

        return style;
    };

    return (
        <div style={{ position: "relative", display: "inline-block", ...style }}>
            <div ref={triggerRef} onClick={togglePopover}>
                {children}
            </div>
            <AnimatePresence mode="wait">
                {isVisible && (
                    <motion.div
                        initial={{ y: 50, opacity: 0, scale: 0.8 }}
                        animate={{ y: 0, opacity: 1, scale: 1 }}
                        exit={{ y: -20, opacity: 0, scale: 0.8 }}
                        transition={{ duration: 0.4, type: "spring", bounce: 0.4 }}
                        ref={popoverRef}
                        style={getPopoverStyle()}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {content}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Popover;
