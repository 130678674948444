import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { MainContext } from "../../ContextState";
import { EditorPageStyled, PagesWrapperStyled } from "../../styles/EditPreivewStyles";
import { bookStatus } from "../../ApiCalls";
import styled from "styled-components";
import { PumpLoaderStyled } from "../../styles/CommonStyled";
import { transformImage } from "../../common/utils";
import ChangingTextsProgress from "./components/ChangingTextsProgress";
import { motion } from "framer-motion";
import InfiniteProgressBar from "./components/ProgressBar";
import { setStageCookie } from "../../common/cookie";
import { chooseLang } from "../../common/chooseLang";

const PreviewWrapperStyled = styled.div`
    .main_text {
        font-size: 30px;
        font-weight: 500;
    }

    .coverPictures {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        min-height: 264px;
        margin: 0;

        @media (max-width: 767px) {
            flex-wrap: wrap;

            .coverImage {
                width: 45%;
                margin: 5px;
            }
            img {
                width: 100%;
                height: 150px;
            }
        }

        @media (max-width: 480px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 3%;
            margin: 20px 0 10px 0;

            .coverImage {
                width: 100%;
                margin: 0px;
            }

            .coverImage.fs {
                width: 100%;
                height: 100%;
            }
        }
    }

    .coverImage {
        margin: 0px 15px;
    }

    .preview-pump {
        height: 250px;
        width: 250px;
        border-radius: 15px;

        @media (max-width: 480px) {
            width: 100%;
            height: 150px;
        }
    }

    .coverImage.fs {
        position: relative;
        width: 250px;
        height: 250px;
        overflow: hidden;
        border-radius: 5px;
    }

    .coverImage.fs .poki-image-0 {
        width: 200%;
        height: 200%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    .coverImage.fs .poki-image-1 {
        width: 200%;
        height: 200%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: -100%;
    }

    .coverImage.fs .poki-image-2 {
        width: 200%;
        height: 200%;
        object-fit: cover;
        position: absolute;
        top: -100%;
        left: 0;
    }

    .coverImage.fs .poki-image-3 {
        width: 200%;
        height: 200%;
        object-fit: cover;
        position: absolute;
        top: -100%;
        left: -100%;
    }

    .coverImage.bookIsReady {
        cursor: pointer;
    }

    img {
        width: 250px;
        height: 250px;
        border-radius: 15px;
    }

    .coverImage.fs {
        img {
            filter: blur(6px);
        }
    }

    .coverImage.upscale {
        img {
            filter: blur(4px);
        }
    }
`;

const GeneralErrorStyled = styled.div`
    h2 {
        text-align: center;
    }
`;

const ProgressPage = () => {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";
    const bookId = queryParameters.get("bookId");

    const wrapperRef = useRef(null);

    const { bookDataContext, configContext } = useContext(MainContext);
    const [bookData] = bookDataContext;

    const [height, s_height] = useState(0);
    const [previewImages, s_previewImages] = useState([]);
    const [isBookReady, s_isBookReady] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState("");
    const [previewImagesStage, s_previewImagesStage] = useState("init");
    const [errorGeneratingBook, s_errorGeneratingBook] = useState(false);

    const langData = chooseLang(lang);

    useEffect(() => {
        document.documentElement.lang = lang;
        document.title = langData.main_title;
    }, [lang]);

    useEffect(() => {
        if (wrapperRef?.current) {
            s_height(wrapperRef.current.offsetWidth);
        }
    }, [wrapperRef?.current, bookData]);

    useEffect(() => {
        getBookStatus(bookId);
    }, []);

    useEffect(() => {
        setStageCookie("process");
    }, []);

    const errorToSever = () => {
        try {
            // call for server
        } catch (e) {
            console.log(e);
        }
    };

    let stopInterval = false;
    let statusCounter = 0;
    const getBookStatus = async (bookId) => {
        while (!stopInterval) {
            try {
                const response = await bookStatus(bookId);
                const jobs = response?.jobs;
                const job = jobs?.find((pp) => pp.bookPageIndex === -1);
                const jobStatus = job?.status;

                if (jobStatus === "face swap") {
                    s_previewImagesStage("fs");
                    if (job.mjGridImageUrl) {
                        s_previewImages([
                            job.mjGridImageUrl,
                            job.mjGridImageUrl,
                            job.mjGridImageUrl,
                            job.mjGridImageUrl
                        ]);
                    }
                }

                if (jobStatus === "upscale") {
                    s_previewImagesStage("upscale");
                    if (job.fs_u1) {
                        s_previewImages([job.fs_u1, job.fs_u2, job.fs_u3, job.fs_u4]);
                    }
                }

                if (response.status === "pending payment") {
                    stopInterval = true;
                    const url = `/preview?bookId=${bookId}&language=${lang}`;
                    window.location.href = url;
                }

                if (response.status === "creation failed") {
                    s_isBookReady(true);
                    stopInterval = true;
                    setError(true);
                    setLoading(false);
                    s_errorGeneratingBook(true);
                }
            } catch (error) {
                console.error("error getting book status", error);
            }

            if (statusCounter > 121) {
                stopInterval = true;
                setLoading(false);
                s_errorGeneratingBook(true);
                errorToSever();
            }
            statusCounter++;
            await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 5 seconds
        }
    };

    const bgVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 }
    };

    const itemVariants = {
        initial: { opacity: 0, scale: 0 },
        animate: { opacity: 1, scale: 1 }
    };

    const getItemAnimation = (delay, isBg) => ({
        initial: "initial",
        animate: "animate",
        variants: isBg ? bgVariants : itemVariants,
        transition: { duration: 0.4, delay }
    });

    return (
        <EditorPageStyled>
            <PagesWrapperStyled ref={wrapperRef}>
                {!errorGeneratingBook && (
                    <>
                        <ChangingTextsProgress lang={langData} />
                        <motion.div {...getItemAnimation(0.2, true)} className="loadingContainer">
                            <InfiniteProgressBar lang={langData} />
                        </motion.div>
                        <PreviewWrapperStyled className="coverSection">
                            <div className="coverPictures">
                                {previewImagesStage === "init" &&
                                    [1, 1, 1, 1].map((op, idx) => {
                                        return <PumpLoaderStyled className="coverImage preview-pump" key={idx} />;
                                    })}
                                {(previewImagesStage === "fs" || previewImagesStage === "upscale") &&
                                    previewImages.map((item, index) => {
                                        const transformedImage = transformImage(item, "w_500");
                                        return (
                                            <div key={index} className={`coverImage ${previewImagesStage}`}>
                                                <img
                                                    src={transformedImage}
                                                    alt={`preview image`}
                                                    className={`poki-image-${index}`}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        </PreviewWrapperStyled>
                    </>
                )}
                {errorGeneratingBook && (
                    <GeneralErrorStyled>
                        <h2>{langData.general_generating_book_error.error}</h2>
                    </GeneralErrorStyled>
                )}
            </PagesWrapperStyled>
        </EditorPageStyled>
    );
};

export default ProgressPage;
