import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Loader from "../../common/Loader";
import { getMobileQuery } from "../../common/utils";

const WrapperStyled = styled.div`
    width: 100%;
    height: 100%;
`;

const TextStyled = styled.div`
    position: absolute;
    bottom: 10%;
    text-align: center;
    width: 100%;
    text-shadow: 1px 1px 2px #000;
`;

const ContStyled = styled.div`
    position: relative;

    img {
        border-radius: 3px;
        display: block;
        object-fit: cover;
    }

    box-shadow:
        0 0 5px -1px black,
        inset -1px 1px 2px rgba(255, 255, 255, 0.5);
    margin: auto;
    border-radius: 5px;
    background-size: cover;

    &.selected {
    }

    .text-p-zdwe {
        font-size: 17px;
        margin-top: 9px;
        color: #000;
        padding: 0 10px;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 12px;
            margin-top: 10px;
        }

        @media (max-width: 360px) {
            margin-top: 5px;
            padding: 0 2px;
        }
    }

    .loader-lottie {
        padding-top: 10px;
        @media (max-width: 767px) {
            padding-top: 20px;
        }

        @media (max-width: 360px) {
            padding-top: 5px;
        }
    }

    .progress-pers {
        position: absolute;
        height: 7px;
        width: 100%;
        bottom: 0;
        direction: ltr;

        .queue-message {
            padding: 0;
            margin: -15px 0 0 0;
            font-size: 13px;
            text-align: center;
        }

        .progress-pers-inside {
            height: inherit;
            background: rgb(255, 196, 61);
            transition: width 1s ease-in-out;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        .progress-pers-inside-cont {
            position: absolute;
            transition: left 1s ease-in-out;

            @media (max-width: 360px) {
                font-size: 11px;
            }
        }
    }

    @media (max-width: 767px) {
        width: 100%;
        height: inherit;
    }
`;

const CoverMediaLoader = ({ contWidth = 250, text, progress, position, langData }) => {
    const wrapperRef = useRef(null);
    const [height, s_height] = useState(contWidth);

    useEffect(() => {
        s_height(contWidth);
    }, [contWidth]);

    useEffect(() => {
        if (wrapperRef?.current) {
            s_height(wrapperRef.current.offsetWidth);
        }
    }, [wrapperRef?.current, contWidth]);

    const innerWidth = getMobileQuery();
    const isMobile = innerWidth < 550;

    let loaderWidth = 150;
    if (isMobile) {
        loaderWidth = 75;
    }

    const style = {
        width: contWidth,
        height
    };

    const contProgress = progress > 0 ? progress : 0;
    let contProgressText = contProgress > 8 ? contProgress - 8 : 1;
    if (contProgress > 95) {
        contProgressText = contProgress - 12;
    }
    if (contProgress === 100) {
        contProgressText = contProgress - 20;
    }

    let top = -23;
    if (innerWidth < 361) {
        top = -8;
    }

    const showQueueMessage = position > 0 && progress === 0;

    return (
        <WrapperStyled ref={wrapperRef}>
            <ContStyled className={"kkop"} style={style}>
                <Loader size={loaderWidth} />
                <div className="text-p-zdwe">{text}</div>
                {showQueueMessage && (
                    <div className="progress-pers">
                        <p className="queue-message">
                            {langData?.queue_text} {position}
                        </p>
                    </div>
                )}
                {!showQueueMessage && (
                    <div className="progress-pers">
                        <div className="progress-pers-inside" style={{ width: `${contProgress}%` }}>
                            <div
                                className="progress-pers-inside-cont"
                                style={{ left: `${contProgressText}%`, top: top }}
                            >
                                {contProgress}%
                            </div>
                        </div>
                    </div>
                )}
            </ContStyled>
        </WrapperStyled>
    );
};

export default CoverMediaLoader;
