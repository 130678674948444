import styled, { keyframes } from "styled-components";

export const ButtonStyled = styled.button`
    background: #ffa927;
    //background: rgb(39, 255, 196);
    color: #000;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    width: fit-content;
    border: none;
    cursor: pointer;
    line-height: 1.3;

    justify-content: center;
    padding: 8px 25px;
    font-size: 25px;
    align-items: start;

    &:disabled {
        background: #cfcbc4;
    }

    &[disabled] {
        background: #cfcbc4;
    }

    &:disabled:hover {
        cursor: default;
        background: #cfcbc4;
    }

    &:hover {
        background: #ffc43d;
    }

    &.isLoading {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border: 4px solid transparent;
            border-top-color: #ffffff;
            border-radius: 50%;
            animation: button-loading-spinner 1s ease infinite;
        }

        span {
            opacity: 0;
        }
    }

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
    }

    &.not_clickable {
        pointer-events: none;
    }
`;

export const ButtonStyledV2 = styled.button`
    margin: auto;
    color: white;
    background: rgb(255, 151, 31);
    background: linear-gradient(101deg, rgba(255, 151, 31, 1) 0%, rgba(255, 169, 39, 1) 100%);
    border-radius: 0.7rem;
    outline: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5em 1rem;
    cursor: pointer;
    transition: 0.1s normal ease-in;
    border: 1px solid transparent;
    font-size: 21px;

    &:hover {
        filter: brightness(99%);
        border: 1px solid white;
        box-shadow: 2px 2px 6px -4px #0004;
    }

    &.isLoading {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border: 4px solid transparent;
            border-top-color: #ffffff;
            border-radius: 50%;
            animation: button-loading-spinner 1s ease infinite;
        }

        span {
            opacity: 0;
        }
    }

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
    }

    &.not_clickable {
        pointer-events: none;
    }
`;

export const ButtonLinkStyled = styled.button`
    background: transparent;
    color: #000;
    font-weight: 500;
    border-radius: 50px;
    display: flex;
    width: fit-content;
    border: none;
    cursor: pointer;
    line-height: 1;
    justify-content: center;
    font-size: 19px;

    &:disabled {
        background: #cfcbc4;
    }

    &[disabled] {
        background: #cfcbc4;
    }

    &:disabled:hover {
        cursor: default;
        background: #cfcbc4;
    }

    &:hover {
        text-decoration: underline;
    }

    svg {
        width: 16px;
        height: 16px;
        margin-inline-start: 4px;
        margin-top: 0;
    }
`;

export const ChooseCoverTitleModal = styled.h2`
    font-size: 28px;
    margin: 20px 0 1px;
`;

const placeHolderPump = keyframes`
    from {
        opacity: 0.2;
    }

    to {
        opacity: 0.8;
    }
`;

export const PumpLoaderStyled = styled.div`
    background: #bfbfbf;
    animation: ${placeHolderPump} 1s alternate infinite cubic-bezier(0.37, 0, 0.63, 1);
`;
