import Cookies from "js-cookie";

export const setStageCookie = (stage) => {
    const currentDomain = window.location.hostname;
    const mainDomain = currentDomain?.split(".")?.slice(-2)?.join(".");

    const cookieDomain = mainDomain ? `.${mainDomain}` : "";

    Cookies.set("kukuku", stage, {
        domain: cookieDomain,
        expires: new Date(new Date().getTime() + 2 * 60 * 1000) // 2 min
    });
};

export const setClientIdCookie = (clientId) => {
    const currentDomain = window.location.hostname;
    const mainDomain = currentDomain?.split(".")?.slice(-2)?.join(".");

    const cookieDomain = mainDomain ? `.${mainDomain}` : "";

    Cookies.set("clientId", clientId, {
        domain: cookieDomain,
        expires: new Date(new Date().setMonth(new Date().getMonth() + 1)) // 1 month
    });
};

export const getStageCookie = () => {
    return Cookies.get("kukuku");
};

export const getClientIdCookie = () => {
    return Cookies.get("clientId");
};

export const getTokenCookie = () => {
    return Cookies.get("liutyr");
};

export const removeTokenCookie = () => {
    const currentDomain = window.location.hostname;
    const mainDomain = currentDomain?.split(".")?.slice(-2)?.join(".");

    const cookieDomain = mainDomain ? `.${mainDomain}` : "";

    Cookies.remove("liutyr", { domain: cookieDomain });
};

export const setTokenCookie = (token) => {
    const currentDomain = window.location.hostname;
    const mainDomain = currentDomain?.split(".")?.slice(-2)?.join(".");

    const cookieDomain = mainDomain ? `.${mainDomain}` : "";

    Cookies.set("liutyr", token, {
        domain: cookieDomain,
        expires: new Date(new Date().setMonth(new Date().getMonth() + 1)) // 1 month
    });

    Cookies.set("liutyr", token, {
        domain: "mebook-frame.pages.dev",
        expires: new Date(new Date().setMonth(new Date().getMonth() + 1)) // 1 month
    });
};
