import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BookPreview from "./BookPreview";
import ClickOnMe from "../ClickOnMe";
import { ButtonStyled } from "../../../../styles/CommonStyled";

const WrapperStyled = styled.div`
    min-width: 727px;
    min-height: 320px;
    display: grid;
    grid-gap: 12px;
    margin-top: 25px;

    @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
    }
`;

const CheckBoxStyled = styled.div`
    width: 300px;
    margin: auto;

    input {
        display: inline-block;
        vertical-align: top;
    }

    label {
        display: inline-block;
        //line-height: 1.2;
        vertical-align: top;
        margin-inline-start: 5px;
    }

    .terms-link {
        cursor: pointer;
        color: #5d35ff;
    }
`;

const PreviewBookAndFinish = ({
    selectedBookCoverImage,
    bookData,
    lang,
    langData,
    textData,
    imagesData,
    finishBookHandle,
    onTermsClick,
    config
}) => {
    const wrapperRef = useRef(null);
    const termsRef = useRef(null);
    const [width, s_width] = useState(0);
    const [terms, s_terms] = useState(false);
    const [onFocus, s_onFocus] = useState(0);

    useEffect(() => {
        if (wrapperRef?.current) {
            s_width(wrapperRef.current.offsetWidth);
        }
    }, [wrapperRef]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (onFocus) {
                switch (event.key) {
                    case " ": // space
                    case "Enter":
                        onTermsClick();
                        break;
                    default:
                        break;
                }
            }
        };

        const divElement = termsRef.current;

        if (divElement) {
            divElement.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [onFocus]);

    const gender = bookData.inputData.gender;

    const templateTitle = gender === "female" ? bookData.template?.titleFemale : bookData.template?.titleMale;
    const coverDesign = bookData.template.coverDesign;
    const book = [];
    textData.forEach((text, idx) => {
        book.push({ img: imagesData[idx] });
        book.push({ text: text });
    });

    const termsUrl = config?.termsUrl;

    return (
        <WrapperStyled ref={wrapperRef}>
            <ClickOnMe langData={langData} lang={lang} />
            <BookPreview
                width={width}
                bookCoverData={{
                    img: selectedBookCoverImage,
                    text: templateTitle,
                    coverDesign
                }}
                lang={lang}
                langData={langData}
                book={book}
            />
            <CheckBoxStyled>
                <input
                    aria-checked={terms}
                    aria-labelledby="scales"
                    onChange={() => s_terms(!terms)}
                    checked={terms}
                    id="scales"
                    type="checkbox"
                />
                <label htmlFor="scales">
                    {langData.terms}
                    <span
                        ref={termsRef}
                        aria-label={langData.terms_part_2}
                        onFocus={() => {
                            s_onFocus(1);
                        }}
                        onBlur={() => {
                            s_onFocus(0);
                        }}
                        tabIndex="0"
                        className="terms-link"
                    >
                        <a rel="noreferrer" target="_blank" href={termsUrl}>
                            {langData.terms_part_2}
                        </a>
                    </span>
                </label>
            </CheckBoxStyled>
            <ButtonStyled
                aria-label={langData.print}
                disabled={!terms}
                onClick={(e) => {
                    e.stopPropagation();
                    finishBookHandle(false);
                }}
                style={{ margin: "auto" }}
            >
                {langData.print}
            </ButtonStyled>
        </WrapperStyled>
    );
};

export default PreviewBookAndFinish;
