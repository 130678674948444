import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const WrapperStyled = styled.div`
    width: 50%;
    margin: 25px auto;

    @media (max-width: 767px) {
        width: 100%;
    }

    .infinite-progress-bar {
        background: #fbf8f8;
        border-radius: 10px;
        height: 15px;
    }

    .bar {
        background: #ffba34;
        height: 15px;
        border-radius: 10px;
    }

    @media (max-width: 480px) {
        .infinite-progress-bar {
            margin: 0 2%;
        }
    }
`;

const InfiniteProgressBar = ({ lang }) => {
    const [progress, setProgress] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress < 100) {
                setProgress(progress + 1);
            } else {
                setProgress(0);
            }
        }, 1200);
        return () => clearInterval(interval);
    }, [progress]);

    return (
        <WrapperStyled className="loading-bar">
            <div className="infinite-progress-bar">
                <motion.div
                    className="bar"
                    initial={{ width: "0" }}
                    animate={{ width: `${progress}%` }}
                    transition={{ duration: 0.5 }}
                />
            </div>
        </WrapperStyled>
    );
};

export default InfiniteProgressBar;
