import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { chooseLang } from "../../../../common/chooseLang";
import { useMediaQuery } from "react-responsive";
import ImageSlider from "./ImageSlider";
import ConsentBox from "./ConsentBox";

const Backdrop = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    z-index: 100;
`;

const ModalContainer = styled(motion.div)`
    width: 100%;
    max-width: 968px;
    position: relative;
    background: white;
    max-height: 80vh;
    border-radius: 1.5rem;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    aspect-ratio: 16/9;

    @media (max-width: 768px) {
        width: 100%;
        aspect-ratio: unset;
        flex-direction: column;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0; /* Optional: Remove border radius for full-screen */
    }

    * {
        box-sizing: border-box;
    }
`;

const LeftSection = styled.div`
    padding: 20px;
    background: #f7f7f7;
    width: 35%;
    aspect-ratio: 1/1;
    position: relative;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: start;

    @media (max-width: 768px) {
        width: 100%;
        aspect-ratio: 1/1;
        padding: 0;
    }
`;

const RightSection = styled.div`
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    overflow: hidden;

    @media (max-width: 768px) {
        padding-bottom: 0;

        h2 {
            margin: 5px 0 10px 0;
            font-size: 1.3rem;
        }
    }

    @media (max-height: 650px) {
        padding: 10px 20px 0 20px;
        h2 {
            font-size: 1.2rem;
        }
    }
`;

const backdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
};

const modalVariants = {
    hidden: { y: 100, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { type: "spring", bounce: 0.2, duration: 0.3 }
    },
    exit: { y: 100, opacity: 0 }
};
const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;

    button {
        border-radius: 0.7rem;
        border: 0;
        outline: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0.5em 1rem;

        background: linear-gradient(135deg, #e0e0e0, #9e9e9e);
        cursor: pointer;
        transition: 0.1s normal ease-in;
        border: 1px solid transparent;
    }

    button:hover {
        filter: brightness(90%);
        border: 1px solid white;
        box-shadow: 2px 2px 6px -4px #0004;
    }

    button:not([data-type="next"]) svg {
        ${(props) => (props?.lang == "he" ? "transform:rotate(180deg)" : "")};
    }

    button[data-type="next"] {
        color: white;
        pointer-events: ${(props) => (props?.isChecked ? "auto" : "none")};
        filter: grayscale(${(props) => (props?.isChecked ? "0" : "1")});
        background: rgb(255, 151, 31);
        background: linear-gradient(101deg, rgba(255, 151, 31, 1) 0%, rgba(255, 169, 39, 1) 100%);
    }

    @media (max-width: 768px) {
        position: sticky;
        bottom: 0;
        justify-content: center;
        background: white;
        padding: 1rem 0;
        width: 100%;

        button {
            width: 100%;
            font-size: 0.8rem;
            padding: 10px 5px;
        }

        button svg {
            width: 16px;
            height: 16px;
        }
    }
`;
const WarningBox = styled.div`
    background: #ffdede;
    color: #ff0808;
    /* border:1px solid #ff0808; */
    border-radius: 0.4rem;
    width: 100%;
    padding: 5px 20px;
    padding-top: 8px;
    @media (max-width: 768px) {
        font-size: 0.8rem;
    }
`;
const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        margin: 0 0px 10px 0;
    }

    div p {
        margin: 0;
    }

    svg {
        flex-shrink: 0;
        fill: #f7921e;
        ${(props) => (props?.lang === "he" ? "margin-left:10px" : "margin-right:10px")};
        stroke: transparent;
        ${(props) => (props?.lang === "he" ? "transform:rotate(-90deg)" : "transform:rotate(90deg)")};
    }

    @media (max-width: 768px) {
        p {
            font-size: 0.8rem;
        }
    }

    .pikl {
        text-decoration: underline;
    }

    strong {
        background: rgb(255, 151, 31);
        padding: 1px 3px;
        color: #fff;
    }
`;
const CloseButton = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    background: white;
    border-radius: 100px;
    display: flex;
    padding: 5px;
`;

const Scrollable = styled.div`
    width: 100%;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 0; /* Important: prevents it from growing larger than its parent */

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fbaf3b;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #fbaf3b;
    }
`;

const Spacer = styled.div`
    width: ${(props) => props?.x / 4}em;
`;

export default function ConsentModal({ isOpen, lang, onClose, images, onConsent, childName }) {
    const [isChecked, setIsChecked] = useState(false);
    const langData = chooseLang(lang);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [step, setStep] = useState(0);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                document.body.classList.add("modal-active");
            }, 200);
        } else {
            document.body.classList.remove("modal-active");
        }
    }, [isOpen]);

    return (
        <AnimatePresence>
            {isOpen && (
                <Backdrop
                    variants={backdropVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    onClick={onClose}
                >
                    <ModalContainer
                        variants={modalVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <LeftSection>
                            <ImageSlider lang={lang} images={images} />
                        </LeftSection>
                        <RightSection>
                            <CloseButton
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-x"
                                >
                                    <path d="M18 6 6 18" />
                                    <path d="m6 6 12 12" />
                                </svg>
                            </CloseButton>
                            <h2>{langData?.consent_popup?.title}</h2>
                            <Scrollable>
                                <>
                                    {(step === 0 || !isMobile) && (
                                        <motion.div
                                            initial={isMobile ? { opacity: 0, x: -100 } : {}}
                                            animate={isMobile ? { opacity: 1, x: 0 } : {}}
                                            style={{ width: "100%", flex: 1 }}
                                            transition={{ type: "tween", duration: 0.4 }}
                                        >
                                            <ListWrapper lang={lang}>
                                                {typeof langData?.consent_popup?.instructions === "function" &&
                                                    langData?.consent_popup?.instructions(childName).map((i, d) => (
                                                        <div key={d}>
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                            >
                                                                <path d="M13.73 4a2 2 0 0 0-3.46 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z" />
                                                            </svg>
                                                            {i.title}
                                                        </div>
                                                    ))}
                                            </ListWrapper>
                                            <WarningBox>{langData?.consent_popup?.warning}</WarningBox>
                                        </motion.div>
                                    )}

                                    {(step === 1 || !isMobile) && (
                                        <motion.div
                                            transition={{ type: "tween", duration: 0.4 }}
                                            initial={isMobile ? { opacity: 0, x: -100 } : {}}
                                            animate={isMobile ? { opacity: 1, x: 0 } : {}}
                                            style={{ width: "100%" }}
                                        >
                                            <ConsentBox
                                                langData={langData}
                                                lang={lang}
                                                checked={isChecked}
                                                onCheck={(e) => setIsChecked(e)}
                                            />
                                        </motion.div>
                                    )}
                                </>
                            </Scrollable>
                            <ButtonWrapper lang={lang} isChecked={(step === 0 && isMobile) || isChecked}>
                                <button
                                    onClick={() => {
                                        step === 1 ? setStep(0) : onClose();
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-chevron-left"
                                    >
                                        <path d="m15 18-6-6 6-6" />
                                    </svg>
                                    <Spacer x={2}></Spacer>
                                    {langData?.consent_popup?.buttons?.back}
                                </button>
                                <div style={{ height: "0.5rem", width: "0.5rem" }}></div>
                                <button
                                    data-type="next"
                                    onClick={() => {
                                        step === 1 || !isMobile ? onConsent() : setStep(1);
                                    }}
                                >
                                    {langData?.consent_popup?.buttons?.continue}
                                    <Spacer x={2}></Spacer>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="lucide lucide-check"
                                    >
                                        <path d="M20 6 9 17l-5-5" />
                                    </svg>
                                </button>
                            </ButtonWrapper>
                        </RightSection>
                    </ModalContainer>
                </Backdrop>
            )}
        </AnimatePresence>
    );
}
