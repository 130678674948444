import styled, { keyframes } from "styled-components";
import { Check } from "../../../../svg";

const moveUpAndDown = keyframes`
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px); /* Move up by 20px */
    }
    100% {
        transform: translateY(0); /* Move back to original position */
    }
`;
const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const CheckboxWrapper = styled.div`
    width: 20px;
    height: 20px;
    border: 2px solid ${(props) => (props.checked ? "rgba(255, 169, 39, 1)" : "#d1d5db")};
    border-radius: 7px;
    ${(props) => (props?.lang === "he" ? "margin-left: 8px;" : "margin-right: 8px;")};
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    background-color: ${(props) => (props.checked ? "rgba(255, 169, 39, 1)" : "white")};
    cursor: pointer;

    &:hover {
        border-color: rgba(255, 169, 39, 1);
    }
`;

const Label = styled.label`
    font-size: 14px;
    color: #374151;
    user-select: none;
`;

const ArrowAnimation = styled.div`
    width: 24px;
    height: 24px;
    position: absolute;
    color: #fca22e;
    background: #fffa;
    border-radius: 20px;
    left: -2px;
    top: -24px;
    animation: ${moveUpAndDown} 2s infinite;

    &.he {
        left: 5px;
    }
`;

export function CustomCheckbox({ label, checked, onCheck, lang }) {
    const toggleCheckbox = () => onCheck(!checked);

    const handleKeyDown = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            toggleCheckbox();
        }
    };

    return (
        <CheckboxContainer>
            <CheckboxWrapper
                lang={lang}
                role="checkbox"
                aria-checked={checked}
                tabIndex={0}
                onClick={toggleCheckbox}
                onKeyDown={handleKeyDown}
                checked={checked}
            >
                {checked && <Check color="white" size={16} />}
            </CheckboxWrapper>
            {checked === false && (
                <ArrowAnimation className={lang === "he" ? "he" : ""}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-move-down"
                    >
                        <path d="M8 18L12 22L16 18" />
                        <path d="M12 2V22" />
                    </svg>
                </ArrowAnimation>
            )}
            <Label onClick={toggleCheckbox} onKeyDown={handleKeyDown}>
                {label}
            </Label>
        </CheckboxContainer>
    );
}
