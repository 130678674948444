import io from "socket.io-client";
import { SOCKET_URL } from "../consts";

const socket = io(SOCKET_URL);

socket.on("connect", () => {
    console.info("socket connected");
});

socket.on("disconnect", () => {
    console.info("socket disconnected");
});

export const register = (eventName) => {
    console.log("register socket event " + eventName);
    socket.on(eventName, (data) => {
        window.dispatchEvent(new CustomEvent(eventName, { detail: { ...data } }));
    });
};

export const unregister = (payload) => {
    console.log("unregister socket event " + payload);
    socket.off(payload);
};
