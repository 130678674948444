import logo from "../../../images/logo.svg";
import styled from "styled-components";
import { useContext } from "react";
import { MainContext } from "../../../ContextState";
import { getTokenCookie, removeTokenCookie } from "../../../common/cookie";
import { ExitSvg } from "../../../svg";

export const HeaderStyled = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    flex-wrap: wrap;
    position: relative;

    .logo-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .logo {
        img {
            height: 72px;
        }
    }
`;

const ExitStyled = styled.div`
    position: absolute;
    right: 250px;
    top: 21px;
    cursor: pointer;

    @media (max-width: 767px) {
        right: 10px;
        top: 51px;
    }

    svg {
        width: 24px;
        height: 24px;
    }
`;

function Header({ clientId, lang }) {
    const { bookDataContext, configContext } = useContext(MainContext);
    const [bookData] = bookDataContext;
    const [config] = configContext;

    let url = "https://mebook.ai/";
    if (bookData?.client?.websiteUrl) {
        url = bookData.client.websiteUrl;
    }
    const token = getTokenCookie();

    let logoToShow = logo;
    if (config?.clientImageUrl) {
        logoToShow = config.clientImageUrl;
    }
    const frameUrl = config?.frameUrl || "https://frame.mebook.ai";

    return (
        <HeaderStyled>
            <div className="logo-container">
                {config && (
                    <div className="logo">
                        <a href={url} target="_blank" rel="noreferrer">
                            <img src={logoToShow} alt="logo" />
                        </a>
                    </div>
                )}
            </div>
            {token && (
                <ExitStyled
                    onClick={() => {
                        removeTokenCookie();
                        window.location.href = `${frameUrl}?clientId=${clientId}&language=${lang}`;
                    }}
                >
                    <ExitSvg />
                </ExitStyled>
            )}
        </HeaderStyled>
    );
}

export default Header;
