import React, { useState } from "react";
import { styled } from "styled-components";
import Popover from "./Popover";

import { HexColorPicker } from "react-colorful";

const PickerRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    align-items: center;
    margin: 10px 0;
    flex-wrap: nowrap;

    .currentcolor {
        width: 20%;
        height: 50px;
        flex-shrink: 0;
        border: 2px solid #f2f2f2;
        border-radius: 5rem;
        box-shadow: 2px 2px 8px -3px #0006;
    }

    .spacer {
        width: 1rem;
        height: 1rem;
        flex-shrink: 0;
    }

    .picker {
        width: 50px;
        border-radius: 1rem;
        height: 50px;
        background: #f59e0b;
        cursor: pointer;
        transition: 0.1s normal ease-in;
    }

    .picker:hover {
        filter: brightness(90%);
    }

    .picker svg {
        padding: 10px;
    }

    .picker path {
        fill: white;
    }

    p,
    .color-picker-title {
        font-size: 0.8rem;
        margin: 0;
        flex-shrink: 0;
    }

    .input {
        width: 100%;
        background: #f2f2f2;
        outline: none;
        border: none;

        padding: 10px 20px;
        border-radius: 0.7rem;
    }
`;

const ColorPicker = React.memo(({ value, onChange, title, placeholder, lang }) => {
    const [forceVisibilityHandle, setForceVisibilityHandle] = useState(false);

    return (
        <PickerRow key={"picker"}>
            <div
                onClick={() => {
                    setForceVisibilityHandle(!forceVisibilityHandle);
                }}
                className="currentcolor"
                style={{ backgroundColor: value }}
            ></div>
            <div className="spacer"></div>
            <Popover
                forceVisibilityHandle={forceVisibilityHandle}
                onClose={() => {
                    setForceVisibilityHandle(false);
                }}
                lang={lang}
                position="top"
                style={{ userSelect: "none" }}
                content={
                    <HexColorPicker
                        color={value}
                        onChange={(e) => {
                            onChange(e);
                        }}
                    ></HexColorPicker>
                }
            >
                <div className="picker">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16">
                        <g fill="#434343" fillRule="evenodd">
                            <path
                                d="M15.308 4.434c.912-.911.81-2.495-.229-3.534-1.041-1.039-2.624-1.142-3.534-.23l-1.181 1.183 3.764 3.764zM5.468 14.276l6.147-6.148.772.772 1.584-1.586-5.309-5.309-1.585 1.584.773.773-6.148 6.146L.02 15.201l.754.754zm3.448-8.848l1.635 1.636-6.262 6.26-2.594.96.959-2.596z"
                                transform="translate(1)"
                            />
                        </g>
                    </svg>
                </div>
            </Popover>

            <div className="spacer"></div>
            <p className="color-picker-title">{title}</p>
            <div className="spacer"></div>
            <input
                key={"picker"}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                className="input"
                placeholder={placeholder}
            />
        </PickerRow>
    );
});

export default ColorPicker;
