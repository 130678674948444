import React from "react";
import styled from "styled-components";
import Loader from "../../common/Loader";

export const PayForMoreStyled = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .progress-pers {
        position: absolute;
        height: 7px;
        width: 100%;
        bottom: 0;
        direction: ltr;

        .queue-message {
            padding: 0;
            margin: -15px 0 0 0;
            font-size: 15px;
            text-align: center;
        }

        .progress-pers-inside {
            height: inherit;
            background: rgb(255, 196, 61);
            transition: width 1s ease-in-out;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .progress-pers-inside-cont {
            position: absolute;
            transition: left 1s ease-in-out;
        }
    }
`;

export const PayForMoreTextStyled = styled.div`
    //background: rgba(000, 000, 000, 0.4);
    position: absolute;
    width: 100%;
    top: 30%;
    height: 90px;
    color: #fff;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    text-align: center;
    font-size: 20px;
    align-content: center;
    display: grid;

    .text-p-zdwe {
        font-size: 22px;
        margin-top: 44px;
        color: #000;
        padding: 0 10px;
    }
`;

const ShowMediaLoader = ({ tabIndex, label, text, progress, position, langData }) => {
    const contProgress = progress > 0 ? progress : 0;
    let contProgressText = contProgress > 5 ? contProgress - 5 : 1;
    if (contProgress > 95) {
        contProgressText = contProgress - 9;
    }
    if (contProgress === 100) {
        contProgressText = contProgress - 14;
    }

    const showQueueMessage = position > 0 && progress === 0;

    return (
        <PayForMoreStyled>
            <PayForMoreTextStyled aria-label={label} tabIndex={tabIndex}>
                <Loader size={150} />
                <div className="text-p-zdwe">{text}</div>
            </PayForMoreTextStyled>
            {showQueueMessage && (
                <div className="progress-pers">
                    <p className="queue-message">
                        {langData?.queue_text} {position}
                    </p>
                </div>
            )}
            {!showQueueMessage && (
                <div className="progress-pers">
                    <div className="progress-pers-inside" style={{ width: `${contProgress}%` }}>
                        <div className="progress-pers-inside-cont" style={{ left: `${contProgressText}%`, top: -23 }}>
                            {contProgress}%
                        </div>
                    </div>
                </div>
            )}
        </PayForMoreStyled>
    );
};

export default ShowMediaLoader;
