import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

const NavButton = styled.button`
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    background: rgb(255, 151, 31);
    background: linear-gradient(101deg, rgba(255, 151, 31, 1) 0%, rgba(255, 169, 39, 1) 100%);
    color: white;
`;

const PrevButton = styled(NavButton)`
    ${(props) => (props?.lang === "he" ? "transform:rotate(180deg)" : null)};
`;

const NextButton = styled(NavButton)`
    ${(props) => (props?.lang === "he" ? "transform:rotate(180deg)" : null)};
`;

const Indicator = styled.div`
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    z-index: 2;
`;

const Dot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => (props.active ? "#fff" : "rgba(0, 0, 0, 0.2)")};
    cursor: pointer;
`;
const NavWrapper = styled.div`
    display: flex;
    z-index: 2;
    margin-top: 10px;

    align-items: center;
    justify-content: start;
    position: relative;

    @media (max-width: 768px) {
        position: absolute;
        width: 100%;
        padding: 1rem;
        color: white;
        left: 0;
        bottom: 0;
    }

    p {
        margin: 0 10px;
        flex-direction: ${(props) => (props?.lang === "he" ? "row-reverse" : "row")};
    }
`;
const StyledGradient = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    height: 60%;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
`;

const SliderContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 600px;

    overflow: hidden;
    border-radius: 10px;
    aspect-ratio: 1/1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        max-width: unset;
        border-radius: 0px;
    }
`;

const SlideImage = styled(motion.img)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const ImageSlider = ({ images, lang }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <>
            <SliderContainer>
                <StyledGradient />
                <AnimatePresence initial={false} mode="wait">
                    <SlideImage
                        key={currentIndex}
                        src={images[currentIndex]}
                        initial={{ x: "10%", opacity: 0 }}
                        animate={{ x: "0%", opacity: 1 }}
                        exit={{ x: "-10%", opacity: 0 }}
                        transition={{ duration: 0.2, delay: 0 }}
                    />
                </AnimatePresence>

                <Indicator>
                    {images.map((_, index) => (
                        <Dot key={index} active={index === currentIndex} onClick={() => setCurrentIndex(index)} />
                    ))}
                </Indicator>
            </SliderContainer>
            <NavWrapper lang={lang}>
                <PrevButton lang={lang} onClick={handlePrev}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-chevron-left"
                    >
                        <path d="m15 18-6-6 6-6" />
                    </svg>
                </PrevButton>

                <p>
                    <span>
                        {currentIndex + 1}/{images?.length}
                    </span>
                </p>
                <NextButton lang={lang} onClick={handleNext}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-chevron-right"
                    >
                        <path d="m9 18 6-6-6-6" />
                    </svg>
                </NextButton>
            </NavWrapper>
        </>
    );
};

export default ImageSlider;
